import React from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./plusicon.module.scss"
import HK from 'factory/modules/HK'
const PlusIcon = ({text, menuActual}) => {
    return (
        <div className={convertToModule(styles, menuActual ? 'plus_icon plus-icon-side-menu' : 'plus_icon')}>
            <img src={HK.getImage('/checkout/plus-icon.svg')} alt="plus_icon_black.svg" className={convertToModule(styles, "plus_image")}/>
            <p className={convertToModule(styles, "text")}>{text}</p>
        </div>
    )

}
export default PlusIcon