import { convertToModule } from 'factory/utils/clientUtils';
import styles from './bogo-item.module.scss';
import React from 'react';
import HK from 'factory/modules/HK';
import PlusIcon from '../../plusIcon';
import Link from 'components/Assets/LinkHref';
import DeliveryDate from '../../DeliveryDate';
import QuantitySelector from 'components/Pdp/common/quantitySelector/QuantitySelector';


const BogoItem = ({data, removeBxgyOffer, parentState, onDecrement, onIncrement, removeCart, parentProps, desktopCartMenu}) => {

    const renderRemoveBXGYBtn = () => (
        parentProps.data.spCartFreebie &&
        parentProps.data.spCartFreebie.map((data) => {
            return (
                <div className={convertToModule(styles, "bogo__cta")}>
                    <button className={convertToModule(styles, "bogo")}>
                        Buy One Get One(BOGO) Free
                    </button>
                    <a 
                        data-wishlist-role="" 
                        data-role="item-remove" 
                        data-wishlist-cart-id="14639" 
                        onClick={removeBxgyOffer} 
                        className={convertToModule(styles, "remove_offer")}
                    >
                        Remove Offer
                    </a>
                </div>
            )
        })
    )

    return (
        <div className={convertToModule(styles, "bogo__container")}>
            <PlusIcon text='Buy One Get One (BOGO) Free' />
                {/* <div className={convertToModule(styles, 'bogoPlus')}>
                    <span className={convertToModule(styles, 'middle_bogo')}>Buy One Get One(BOGO) Free</span>
                </div> */}
                {desktopCartMenu ?
                <div className={convertToModule(styles, "cartitem-list")}>
                    <div className={convertToModule(styles, "cart-image")}>
                        <img name="tumbnail" src={data.pr_img && data.pr_img.t_link && data.pr_img.t_link.replace('http://', 'https://')} alt={data.pr_img && data.pr_img.alt} className={convertToModule(styles, "prod-image")}/>
                        {/* <div className={convertToModule(styles, "cart-item-delete")} title="Remove" onClick={removeCart} >
                            <img src={HK.getImage("/checkout/delete-icon-xs.svg")} alt="close" />
                        </div> */}
                    </div>
                    <Link className={convertToModule(styles, "cart-item-name")} to={data.url}>
                        {data.catPgDisNm ? data.catPgDisNm : data.sv_nm.split(",")[0]}
                        <div className={convertToModule(styles, "cart-item__flavour")}>{(data.sv_nm.split(",")[1]) || " "}</div>
                    </Link>
                </div>
                :
                <div className={convertToModule(styles, "cart-item")}>
                    <div className={convertToModule(styles, "cart-item__details")}>
                        <Link className={convertToModule(styles, "cart-item__image gtm_id")} to={data.url}>
                            <img name="tumbnail" src={data.pr_img && data.pr_img.t_link && data.pr_img.t_link.replace('http://', 'https://')} alt={data.pr_img && data.pr_img.alt} />
                            {/* <img name="tumbnail" src={'3543/prd_354239-MuscleBlaze-IsoZero-2.2-lb-Chocolate_c_t.jpg'} /> */}
                        </Link>
                        <div className={convertToModule(styles, "cart-item__desc")}>
                            <Link to={data.url} className={convertToModule(styles, "cart-item__name")}>
                                {data.catPgDisNm ? data.catPgDisNm : data.sv_nm.split(",")[0]}
                                <div className={convertToModule(styles, "cart-item__flavour")}>{(data.sv_nm.split(",")[1]) || " "}</div>
                            </Link>
                            {/* <div className={convertToModule(styles, "cart-item__price")}>
                                {
                                    data.totOffPr !== data.totMrp &&
                                    <span className={convertToModule(styles, "cart-item_total-price")}>
                                        ₹{data.totMrp.toLocaleString()}
                                    </span>
                                }
                                <span className={convertToModule(styles, "cart-item__offer-price")}>
                                    ₹{data.totOffPr.toLocaleString()}
                                </span>
                                {
                                    (data.totMrp - data.totOffPr) > 0 &&
                                    <span className={convertToModule(styles, "cart-item__discount")}>
                                        You Saved ₹{(data.totMrp - data.totOffPr).toLocaleString()}
                                    </span>
                                }
                            </div> */}
                            {
                                data && 
                                data.hkrDeliveryResponse &&
                                data.hkrDeliveryResponse.estDeliveryDate &&
                                <div className={convertToModule(styles, "cart-item__date")}>{`Delivery by ${HK.formatDate(data.hkrDeliveryResponse.estDeliveryDate, 'new').f4}`}</div>
                            }
                        </div>
                    </div>
                    {
                        !HK.isMobile() &&
                        renderRemoveBXGYBtn()
                    }
                    {HK.isMobile() &&
                        <div className={convertToModule(styles, 'bogocart')}>
                            <QuantitySelector 
                                quantity={parentState.itemInp} 
                                hideLabel
                                onDecrement={onDecrement}
                                onIncrement={onIncrement}
                                removeCart={removeCart}
                            />
                        </div>
                    }
                    {
                        HK.isMobile() &&
                        renderRemoveBXGYBtn()
                    }
                </div>
                }
            </div>
    );
}
 
export default BogoItem;