import React, { Component } from 'react';
import HK from '../../../../factory/modules/HK';


const colorOne = "#81ba24";
const colorTwo = "#07b0b4";
const colorThree = "#d18e43";
const colorOne_blur = "#81ba249f";
const colorTwo_blur = "#07b0b49f";
const colorThree_blur = "#d18e439f";
class TBSLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorOne: colorOne_blur,
            colorTwo: colorTwo,
            colorThree: colorThree
        }
        this.intervalID = 0;
    }

    componentDidMount() {
        this.startLoader();
    }

    startLoader = () => {
        //this.setState({colorOne:"#81ba24"});
        let $this = this;
        this.intervalID = setInterval(() => {
            $this.switchColors();
        }, 300);
    }

    switchColors = () => {
        if (this.state.colorOne === colorOne_blur) {
            this.setState({ colorOne: colorOne, colorTwo: colorTwo_blur });
        } else if (this.state.colorTwo === colorTwo_blur) {
            this.setState({ colorTwo: colorTwo, colorThree: colorThree_blur });
        } else if (this.state.colorThree === colorThree_blur) {
            this.setState({ colorThree: colorThree, colorOne: colorOne_blur });
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return (
            <div className="tbs-loader">
                <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" space="preserve">
                    <path fill="#003D78" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50" transform="rotate(311.498 50 50)">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
                    </path>
                </svg>
            </div >
        )
    }
}

export default TBSLoader;