import React, { useEffect } from "react";
import { convertToModule } from "factory/utils/clientUtils";
import styles from "./confrimBox.module.scss";
import HK from "factory/modules/HK";

const ConfirmModal = (props) => {
  const isMobile = HK.isMobile() ? "Mobile" : "";

  useEffect(() =>{
    HK.stopScroll(true)
    return(() => HK.stopScroll(false))
  }, [])


  const handleWishlist = () =>{
    props.moveToWishList()
    handleClosePopup()

  }

  const handleRemoveItem = () =>{
    props.removeCart()
    handleClosePopup()    
  }

  const handleClosePopup = () =>{
    props.handleDeletePopup(false)
  }


  return (
    <div className={convertToModule(styles, `confirmBox  ${isMobile}`)}>
      <div className={convertToModule(styles, "confirmBox-container ")}>
        <div className={convertToModule(styles, "heading")}>
          <span>Remove item</span>
          <img onClick={() => handleClosePopup()} src={HK.getImage("/cart/delete.svg")} />
        </div>
        <div className={convertToModule(styles, "main-container")}>
          <div className={convertToModule(styles, "bottom-container")}>
            <img src={props.src}  alt=""/>
            <div className={convertToModule(styles, "text")}>Are you sure you want to remove this item from the cart?</div>
          </div>
          <div className={convertToModule(styles, "confirmBox-btn")}>
          <button onClick={() => handleRemoveItem()} className={convertToModule(styles, "remove")}>REMOVE</button>
          <button onClick={() => handleWishlist()} className={convertToModule(styles, "wishlist")}>MOVE TO WISHLIST</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
