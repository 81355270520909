import React from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from './quantitySelector.module.scss';
import IntersectionObserver from 'components/common/IntersectionObserver';
import HK from 'factory/modules/HK';
import PDPEventTracker from '../../common/PDPEventTracker';
import { connect } from 'react-redux';
const QuantitySelector = (props = {}) => {
    (typeof window != "undefined") && localStorage.setItem("quantity", props.quantity)

    const triggerInViewEvent = (inView) => {
        if (!inView) return;
        try {
            const eventData = {
                widgetName: 'Quantity Update',
                eventName: 'QUANTITY_UPDATE',
                quantity: props.quantity
            }
            HK.triggerComponentViewEvent(PDPEventTracker.getData(eventData, props.reviewData));
        } catch (err) {
            console.log(err)
        }
    }

    const Plus = (props) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.514" height="11.516" viewBox="0 0 11.514 11.516">
                <path id="close_5_" data-name="close (5)" d="M4.568,4,7.575.99A.5.5,0,0,0,6.867.282L3.861,3.289.854.282A.5.5,0,0,0,.147.99L3.153,4,.147,7a.5.5,0,1,0,.707.707L3.861,4.7,6.867,7.71A.5.5,0,1,0,7.575,7Zm0,0" transform="translate(5.838 0.217) rotate(45)" fill="#E57321" stroke="#E57321" stroke-width="0.4" />
            </svg>
        )
    }
    const Minus = (props) => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="11.484" height="11.486" viewBox="0 0 11.484 11.486">
                <path id="close_5_" data-name="close (5)" d="M7.575.99A.5.5,0,0,0,6.867.282C3.86,3.252,3.107,4.069.147,7a.5.5,0,1,0,.707.707Z" transform="translate(5.838 0.188) rotate(45)" fill="#9393A7" stroke="#E57321" stroke-width="0.4" />
            </svg>

        )
    }
    const express = typeof window != "undefined" ? HK.getSearchParmas().expressCheckout : HK.getSearchParmas(null, props._serverReducers.serverRequestQuery).expressCheckout

    return (
        <IntersectionObserver className={convertToModule(styles, "quantitySelector")} onChange={triggerInViewEvent}>
            {
                // !props.hideLabel &&
                // <div className={convertToModule(styles, "fnt-14")}>Select Quantity</div>
            }
            <div className={convertToModule(styles, 'hk-elements--quantitySelector')}>
                {props.quantity > 1 && (typeof window != "undefined" ? (window.pageType === "cart" || express) : props.pageType) ? <div id='decrement' className={convertToModule(styles, ("decrementCls" + (props.quantity === 1 ? ' disabled' : '')))} onClick={props.onDecrement}> <span><Minus /></span> </div> :
                    (typeof window != "undefined" ? (window.pageType === "cart" || express) : props.pageType) && <div id='decrement' className={convertToModule(styles, ("decrementCls" + ((props.quickBuy && props.dataIndex == 0 && !props.comboItemLength) ? ' disabled' : '')))} onClick={props.handleDeletePopup}> <Minus /> </div>
                }
                {(!express && (typeof window != "undefined" ? window.pageType != "cart" : (props.pageType != "cart"))) && <div id='decrement' className={convertToModule(styles, ("decrementCls" + (props.quantity === 1 ? ' disabled' : '')))} onClick={props.onDecrement}> <span><Minus /></span> </div>}
                <div id='quantity' className={convertToModule(styles, "quantityCls")}> {props.quantity} </div>
                <div id='increment' className={convertToModule(styles, "incrementCls")} onClick={props.onIncrement}> <span className={convertToModule(styles, 'plus')}><Plus /></span> </div>
            </div>
        </IntersectionObserver>
    );
}
function mapStateToProps(state) {
    return {
        _serverReducers: state.serverReducers
    };
}
export default connect(mapStateToProps, null)(QuantitySelector);

