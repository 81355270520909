export const CartUtility = {
    removeFromCartEvent: (d, page) => {
        try {
            const ecomDataLayer = {
                'event': 'LS_MB_removeFromCart',
                'ecommerce': {
                    'remove': {                               // 'remove' actionFieldObject measures.
                        products: [{
                            "name": d.sv_nm,
                            "id": d.sv_id,
                            "price": d.totLP,
                            "brand": d.brand,
                            "category": d.catName,
                            "variant": "",
                            "quantity": d.qty,
                            "dimension6": page || window.screenName,
                            "dimension7": d.oos ? "Out_of_Stock" : "In_Stock",
                            "dimension8": d.discount,
                            "dimension9": "",
                            "dimension10": "Normal Flow",
                        }]
                    }
                }
            }
            window.dataLayer.push(ecomDataLayer)
        } catch (err) {
            console.log(err)
        }
    }
}