import { convertToModule } from 'factory/utils/clientUtils';
import styles from './freebie-item.module.scss';
import React from 'react';
import Itrack from 'utility/Itrack';
import HK from 'factory/modules/HK';

const FreebieItem = ({ data, isCartLevelFreebie }) => {
    return (
        <div className={convertToModule(styles,isCartLevelFreebie ? "freebie__container freebie__container-cart-level" :"freebie__container")}>
            {
                data.pr_img && data.pr_img.sLink ?
                    <div className={convertToModule(styles,isCartLevelFreebie ? "freebie__img-container-cart-level freebie__img-container" : "freebie__img-container")}>
                        <img name="tumbnail" className={convertToModule(styles, "freebie__image")} src={data.pr_img && data.pr_img.sLink ? data.pr_img.sLink.replace('http://', 'https://') : ''} alt={data.pr_img && data.pr_img.alt} />
                    </div>

                    :
                    <div className={convertToModule(styles,isCartLevelFreebie ? "freebie__img-container-cart-level freebie__img-container" : "freebie__img-container")}>
                        <img name="tumbnail" className={convertToModule(styles, "freebie__image")} src={HK.getImage('/pdp/freebie_icons_default.svg')} alt={data.pr_img && data.pr_img.alt} />
                    </div>

            }

            <div className={convertToModule(styles,isCartLevelFreebie ?  "freebie__details freebie__details-cart-level" : "freebie__details")}>
                {
                    data.urlFragment ?
                        <Itrack url={"/sv" + data.urlFragment + (data.navKey ? "?navKey=" + data.navKey : "")} isLink={true} classname={convertToModule(styles, 'freebie__name')} nam='freebie item' childOf='freebie' isFullUrl={true}>
                            {data.freebieNm}
                        </Itrack>
                        :
                        <div className={convertToModule(styles, 'freebie__name')}>{data.freebieNm}</div>
                }

                {/* <div className={convertToModule(styles, 'freebie__price')}>
                    <p className={convertToModule(styles, "offer")}>₹0.00</p>
                    {
                        data.mrp &&
                        <p className={convertToModule(styles, "mrp")}>₹{data.mrp}</p>
                    }
                </div>
                {
                    data && 
                    data.hkrDeliveryResponse &&
                    data.hkrDeliveryResponse.estDeliveryDate &&
                    <div className={convertToModule(styles, "freebie__delivery")}>{`Delivery by ${HK.formatDate(data.hkrDeliveryResponse.estDeliveryDate, 'new').f4}`}</div>
                } */}

                <div className={convertToModule(styles, 'freebie__tag')}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={HK.isMobile() ? "10px" : "12.038"} height={HK.isMobile() ? '12px' :"13.403"} viewBox="0 0 12.038 13.403">
                        <path id="Union_232" data-name="Union 232" d="M2585.135,15136H2579.5a.574.574,0,0,1-.419-.18.63.63,0,0,1-.174-.436v-6.561a.2.2,0,1,1,.4,0v6.561a.215.215,0,0,0,.058.146.2.2,0,0,0,.14.057h2.807v-6.764a.2.2,0,1,1,.4,0v6.764h2.236v-7.379h-6.345a.6.6,0,0,1-.593-.615v-1.348a.6.6,0,0,1,.593-.615h3.318a3.989,3.989,0,0,1-.413-.529,2.018,2.018,0,0,0-2.027-1,.2.2,0,0,1-.236-.156.2.2,0,0,1,.149-.244,2.375,2.375,0,0,1,2.439,1.168c.079.115.152.215.221.3a2.133,2.133,0,0,1-.106-1.416,1.075,1.075,0,0,1,.537-.666,1.006,1.006,0,0,1,.836,0,.649.649,0,0,0,1.247,0,1,1,0,0,1,.834,0,1.073,1.073,0,0,1,.538.666,1.919,1.919,0,0,1,.045.893,2.338,2.338,0,0,1,2.281-.949.2.2,0,0,1,.15.244.2.2,0,0,1-.236.156,2.02,2.02,0,0,0-2.028,1,3.929,3.929,0,0,1-.413.529h3.318a.6.6,0,0,1,.593.615v1.348a.6.6,0,0,1-.593.615h-.315v7.176a.632.632,0,0,1-.173.436.574.574,0,0,1-.42.18Zm3-.412a.2.2,0,0,0,.14-.057.214.214,0,0,0,.058-.146v-7.176h-3v7.379Zm.907-7.789a.2.2,0,0,0,.2-.205v-1.348a.2.2,0,0,0-.2-.205h-10.453a.2.2,0,0,0-.2.205v1.348a.2.2,0,0,0,.2.205h10.453Zm-3.987-2.168a1.96,1.96,0,0,0,.377-.564,1.725,1.725,0,0,0,.119-1.184.667.667,0,0,0-.328-.419.632.632,0,0,0-.521.011,1.373,1.373,0,0,0-.53.422,2.233,2.233,0,0,1,.137.264,2.156,2.156,0,0,1,.138,1.471Zm-1.03,0a1.62,1.62,0,0,0-.074-1.3c-.007-.018-.014-.031-.021-.047-.007.016-.014.029-.021.047a1.614,1.614,0,0,0-.073,1.3Zm-.613,0a2.161,2.161,0,0,1,.137-1.471,2.31,2.31,0,0,1,.138-.264,1.366,1.366,0,0,0-.529-.422.624.624,0,0,0-.521-.011.663.663,0,0,0-.328.419,1.726,1.726,0,0,0,.118,1.184,1.961,1.961,0,0,0,.377.564Z" transform="translate(-2577.8 -15122.797)" fill="#fff" stroke="#fff" stroke-width="0.4" />
                    </svg>
                    <span>Freebie</span>
                </div>
            </div>
        </div>
    );
}

export default FreebieItem;